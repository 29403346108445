<template>
  <div>
      <h1>This is API Usage component</h1>
  </div>
</template>

<script>
export default {
    name:'ApiUsage'
}
</script>

<style>

</style>